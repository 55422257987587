import styled from "styled-components";
import theme from "styles/theme";
import { device, size } from "styles/utils";
import { HashLink } from "react-router-hash-link";
export const Container = styled.nav `
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding-bottom: 2em;

  a {
    text-decoration: none;
    color: ${theme.colorWhite};
  }
`;
export const NavLink = styled.div `
  padding: 0.2em 2em;
  margin-bottom: 1em;
  font-size: 0.8em;

  &:last-of-type {
    border-right: none;
  }

  & ul {
    padding-left: 1.5em;

    @media screen and (max-width: ${size.mobileL}) {
      padding-left: 0.5em;
    }
  }
  & li {
    padding-top: 0.75em;
    font-size: 0.8em;
  }

  @media screen and ${device.mobileL} {
    font-size: 1em;
  }
`;
export const HashNavLink = styled(HashLink) `
  margin-bottom: 1em;
  font-size: 0.9em;

  @media screen and ${device.mobileL} {
    font-size: 1em;
  }
`;
