import styled from "styled-components";
import theme from "styles/theme";
import { device } from "styles/utils";
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  padding: 1em;
  background-color: ${theme.colorWhite};
`;
export const ContactRow = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
`;
export const ContactCol = styled.div `
  display: grid;
  grid-template-rows: 1fr 0.5fr;
  grid-gap: 1em;
  justify-items: center;
  align-items: center;
`;
export const ContactText = styled.div `
  font-size: 0.9em;
  text-align: center;
  @media screen and ${device.tablet} {
    font-size: 1em;
  }
`;
