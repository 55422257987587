export const solutionsPath = "/solutions";
export const aboutUsPath = "/about-us";
export const contactPath = "/contact";
export const ednarPath = `${solutionsPath}/ednar`;
export const energyWorkbenchPath = `${solutionsPath}/energy-workbench`;
export const powerFactoryPath = `${solutionsPath}/power-factory`;
export const sincalPath = `${solutionsPath}/sincal`;
export const planningEngineersPath = `${solutionsPath}/planningEngineers`;
export const fieldWorkersPath = `${solutionsPath}/fieldWorkers`;
export const dataEngineersPath = `${solutionsPath}/dataEngineers`;
export const operationalPlannersPath = `${solutionsPath}/operationalPlanners`;
export const managementPath = `${solutionsPath}/management`;
export const developersPath = `${solutionsPath}/developers`;
