import React, { memo } from "react";
import * as styles from "./styles";
import ProductDetails from "components/SolutionDetails";
import Image from "components/Image";
import sincal from "assets/images/logos/sincal.svg";
import { useTranslation } from "react-i18next";
import * as commonStyles from "styles/common-styles";
import SoftwareSolutions from "components/SoftwareSolutions";
import screen1 from "assets/images/sincal/sincal1.png";
import screen2 from "assets/images/sincal/sincal2.png";
const { ImageCaptionCenter } = styles;
const { Description, DescriptionText, Grid, InfoCard, WideInfoCard, ProductContainer } = commonStyles;
export default memo(() => {
    const { t } = useTranslation("sincal");
    return (React.createElement(ProductContainer, null,
        React.createElement(ProductDetails, { title: t("title"), summary: t("summary1"), logoSrc: sincal, description: React.createElement(React.Fragment, null,
                React.createElement(Description, null,
                    React.createElement(Grid, { className: "aligned-top" },
                        React.createElement(WideInfoCard, null,
                            React.createElement(DescriptionText, null, t("description1Text1"))),
                        React.createElement(InfoCard, null,
                            React.createElement(DescriptionText, null, t("description1Text2")),
                            React.createElement(DescriptionText, null, t("description1Text3"))),
                        React.createElement(InfoCard, null,
                            React.createElement(Image, { src: screen1, alt: t("description1Image1Alt") })),
                        React.createElement(InfoCard, null,
                            React.createElement(Image, { src: screen2, alt: t("description1Image1Alt") })),
                        React.createElement(InfoCard, null,
                            React.createElement(DescriptionText, null, t("description1Text4")))))) }),
        React.createElement(SoftwareSolutions, null)));
});
