import styled from "styled-components";
import { device, size } from "styles/utils";
export const Container = styled.div `
  width: 100%;
  height: 450px;
  background-color: #3b5573;
  color: white;
  bottom: 0;
  padding: 0 1.5em;
  display: flex;
  justify-content: center;
  position: relative;

  @media screen and (max-width: ${size.laptopS}) {
    height: 550px;
    font-size: 1em;
  }

  @media screen and (max-width: ${size.mobileL}) {
    height: 550px;
    font-size: 1em;
  }
`;
export const Content = styled.div `
  padding: 2em;
`;
export const Legals = styled.div `
  text-align: center;
  font-weight: 300;
  font-size: 0.8em;
  padding-top: 3em;
`;
export const Logo = styled.img `
  margin-left: auto;
  margin-right: auto;
  height: 50px;
  display: block;
}
  
  @media screen and ${device.laptopM} {
    display: none;
  }
`;
