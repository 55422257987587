import React, { memo } from "react";
import * as styles from "./styles";
import * as commonStyles from "styles/common-styles";
import anu from "assets/images/logos/anu.png";
import ausnet from "assets/images/logos/ausnet.png";
import cp from "assets/images/logos/cp.png";
import csiro from "assets/images/logos/CSIRO-Logo.png";
import ee from "assets/images/logos/ee.png";
import pal from "assets/images/logos/pal.png";
import tas from "assets/images/logos/tas.png";
import { useTranslation } from "react-i18next";
import Heading from "components/Heading";
import evergen from "assets/images/logos/evergen.png";
import endeavour from "assets/images/logos/endeavour.png";
import reposit from "assets/images/logos/reposit.png";
import ausgrid from "assets/images/logos/ausgrid.png";
import arena from "assets/images/logos/arena.png";
import aemo from "assets/images/logos/aemo.png";
import nsw from "assets/images/logos/nsw.png";
import unsw from "assets/images/logos/unsw.png";
import evo from "assets/images/logos/evoenergy.png";
import wp from "assets/images/logos/western-power.png";
import electra from "assets/images/logos/electra-nz.png";
import we from "assets/images/logos/we-logo.png";
import northpower from "assets/images/logos/northpower.png";
const { Container, Logo, LogoContainer } = styles;
const { TitleContainer } = commonStyles;
export default memo(() => {
    const { t } = useTranslation("home");
    return (React.createElement(Container, null,
        React.createElement(TitleContainer, null,
            React.createElement(Heading, { title: t("partners.title") })),
        React.createElement(LogoContainer, null,
            React.createElement(Logo, { src: anu }),
            React.createElement(Logo, { src: cp }),
            React.createElement(Logo, { src: electra }),
            React.createElement(Logo, { src: pal }),
            React.createElement(Logo, { src: tas }),
            React.createElement(Logo, { src: csiro }),
            React.createElement(Logo, { src: ee }),
            React.createElement(Logo, { src: ausnet }),
            React.createElement(Logo, { src: evergen }),
            React.createElement(Logo, { src: endeavour }),
            React.createElement(Logo, { src: northpower }),
            React.createElement(Logo, { src: reposit }),
            React.createElement(Logo, { src: ausgrid }),
            React.createElement(Logo, { src: we }),
            React.createElement(Logo, { src: arena }),
            React.createElement(Logo, { src: aemo }),
            React.createElement(Logo, { src: wp }),
            React.createElement(Logo, { src: nsw }),
            React.createElement(Logo, { src: unsw }),
            React.createElement(Logo, { src: evo }))));
});
