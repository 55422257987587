import React, { memo } from "react";
import * as styles from "./styles";
import ProductDetails from "components/SolutionDetails";
import Image from "components/Image";
import eWorkbench from "assets/images/logos/ewb.svg";
import { useTranslation } from "react-i18next";
import * as commonStyles from "styles/common-styles";
import SoftwareSolutions from "components/SoftwareSolutions";
import { Link } from "react-router-dom";
import { contactPath } from "../../util/path";
import screen1 from "assets/images/ewb/ewb1.png";
import screen2 from "assets/images/ewb/ewb2.png";
import screen3 from "assets/images/ewb/ewb3.png";
import screen4 from "assets/images/ewb/ewb4.png";
import screen5 from "assets/images/ewb/ewb5.png";
import question1 from "assets/images/ewb/question1.png";
import question2 from "assets/images/ewb/question2.png";
import question3 from "assets/images/ewb/question3.png";
import question4 from "assets/images/ewb/question4.png";
const { TopGrid, SectionTitle, InfoCardUl, ListWrapper, FindButtonContainer } = styles;
const { Button, ContentLink, Description, DescriptionCta, DescriptionTitle, DescriptionTitleCenter, DescriptionText, Grid, InfoCard, WideInfoCard, ProductContainer } = commonStyles;
export default memo(() => {
    const { t } = useTranslation("energy-workbench");
    return (React.createElement(ProductContainer, null,
        React.createElement(ProductDetails, { title: t("title"), summary: t("summary1"), logoSrc: eWorkbench, description: React.createElement(React.Fragment, null,
                React.createElement(Description, null,
                    React.createElement(Grid, null,
                        React.createElement(InfoCard, null,
                            React.createElement(DescriptionText, null, t("intro1")),
                            React.createElement(DescriptionText, null, t("intro2"))),
                        React.createElement(InfoCard, null,
                            React.createElement(Image, { src: screen1, alt: t("description1Image1Alt") })),
                        React.createElement(InfoCard, null,
                            React.createElement(Image, { src: screen2, alt: t("description1Image1Alt") })),
                        React.createElement(InfoCard, null,
                            React.createElement(DescriptionText, null, t("intro3")),
                            React.createElement(InfoCardUl, null,
                                React.createElement(DescriptionText, null, t("introListText1")),
                                React.createElement("ul", null,
                                    React.createElement("li", null, t("introListItem1")),
                                    React.createElement("li", null, t("introListItem2")),
                                    React.createElement("li", null, t("introListItem3")),
                                    React.createElement("li", null, t("introListItem4")))),
                            React.createElement(DescriptionText, null, t("intro4"))),
                        React.createElement(WideInfoCard, { className: "quote-image" },
                            React.createElement(Image, { className: "question-image", src: question1, alt: t("description1Image1Alt") }),
                            React.createElement(Image, { className: "question-image", src: question2, alt: t("description1Image1Alt") }),
                            React.createElement(Image, { className: "question-image", src: question3, alt: t("description1Image1Alt") }),
                            React.createElement(Image, { className: "question-image", src: question4, alt: t("description1Image1Alt") })),
                        React.createElement(InfoCard, null,
                            React.createElement(DescriptionTitle, null, t("description1Title")),
                            React.createElement(DescriptionText, null, t("description1Text1")),
                            React.createElement(DescriptionText, null, t("description1Text2")),
                            React.createElement(DescriptionText, null, t("description1Text3"))),
                        React.createElement(InfoCard, null,
                            React.createElement(Image, { src: screen3, alt: t("description1Image1Alt") })),
                        React.createElement(InfoCard, null,
                            React.createElement(Image, { src: screen4, alt: t("description1Image1Alt") })),
                        React.createElement(InfoCard, null,
                            React.createElement(DescriptionTitle, null, t("description2Title")),
                            React.createElement("p", null,
                                t("description2Text1-1"),
                                React.createElement(ContentLink, { href: "https://zepben.github.io/evolve/docs/cim/evolve/ ", target: "_blank" },
                                    React.createElement(DescriptionCta, null, t("description2Text1-2"))),
                                t("description2Text1-3")),
                            React.createElement(DescriptionText, null, t("description2Text2"))),
                        React.createElement(InfoCard, null,
                            React.createElement(DescriptionTitle, null, t("description3Title")),
                            React.createElement(DescriptionText, null, t("description3Text1")),
                            React.createElement("div", null,
                                React.createElement(DescriptionText, null, t("description3Text2")),
                                React.createElement(ListWrapper, null,
                                    React.createElement("li", null, t("description3Text2List1")),
                                    React.createElement("li", null, t("description3Text2List2")),
                                    React.createElement("li", null, t("description3Text2List3")),
                                    React.createElement("li", null, t("description3Text2List4")))),
                            React.createElement("div", null,
                                React.createElement(DescriptionText, null, t("description3Text3")),
                                React.createElement(ListWrapper, null,
                                    React.createElement("li", null, t("description3Text3List1")),
                                    React.createElement("li", null, t("description3Text3List2")),
                                    React.createElement("li", null, t("description3Text3List3")))),
                            React.createElement("div", null,
                                React.createElement(DescriptionText, null, t("description3Text4")),
                                React.createElement(ListWrapper, null,
                                    React.createElement("li", null, t("description3Text4List1")))),
                            React.createElement("div", null,
                                React.createElement(DescriptionText, null, t("description3Text5")),
                                React.createElement(ListWrapper, null,
                                    React.createElement("li", null, t("description3Text5List1")))),
                            React.createElement("div", null,
                                React.createElement(DescriptionText, null, t("description3Text6")),
                                React.createElement(ListWrapper, null,
                                    React.createElement("li", null,
                                        React.createElement("p", null,
                                            React.createElement(ContentLink, { href: "https://arena.gov.au/projects/evolve-der-project/", target: "_blank" },
                                                React.createElement(DescriptionCta, null, t("description3Text6List1-1"))),
                                            t("description3Text6List1-2"))),
                                    React.createElement("li", null, t("description3Text6List2")),
                                    React.createElement("li", null, t("description3Text6List3"))))),
                        React.createElement(InfoCard, null,
                            React.createElement(Image, { src: screen5, alt: t("description1Image1Alt") }))),
                    React.createElement(TopGrid, null,
                        React.createElement(WideInfoCard, null,
                            React.createElement(DescriptionTitleCenter, null, t("description4Title"))),
                        React.createElement(InfoCard, null,
                            React.createElement(SectionTitle, null, t("section1Title")),
                            React.createElement(DescriptionText, null, t("section1Text1")),
                            React.createElement(DescriptionText, null, t("section1Text2"))),
                        React.createElement(InfoCard, null,
                            React.createElement(SectionTitle, null, t("section2Title")),
                            React.createElement(DescriptionText, null, t("section2Text1"))),
                        React.createElement(WideInfoCard, null,
                            React.createElement(FindButtonContainer, null,
                                React.createElement(Link, { to: contactPath },
                                    React.createElement(Button, null, t("contactUs")))))))) }),
        React.createElement(SoftwareSolutions, null)));
});
