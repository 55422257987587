import React from "react";
import * as styles from "./styles";
import FooterNav from "components/FooterNav";
import logoWhite from "assets/images/logoWhite.svg";
const { Container, Content, Legals, Logo } = styles;
export default () => {
    return (React.createElement(Container, null,
        React.createElement(Content, null,
            React.createElement(FooterNav, null),
            React.createElement(Logo, { src: logoWhite }),
            React.createElement(Legals, null,
                "\u00A9 Copyright ",
                new Date().getFullYear(),
                " Zeppelin Bend Pty Ltd."))));
};
