import styled from "styled-components";
import theme from "styles/theme";
import { device } from "styles/utils";
export const Container = styled.div `
  padding: 3em;
  background-color: ${theme.colorWhite};
  display: grid;
  grid-template-rows: auto;

  @media screen and ${device.tablet} {
    margin: 2em;
    grid-gap: 2em;
  }

  @media screen and ${device.laptopL} {
    margin: 2em 10em;
  }
`;
export const Text = styled.div `
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.8em;
  margin-top: 0.5em;
`;
export const ItemsContainer = styled.div `
  display: grid;
  grid-template-rows: 1fr;
  margin: unset;
`;
export const ItemGroup = styled.div `
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-content: center;
  justify-items: center;
  border-radius: 10px;
  grid-gap: 2.5em;

  @media screen and ${device.laptopM} {
    padding: 1.5em;
    grid-template-rows: auto;
    grid-template-columns: ${({ newLine }) => (newLine ? "1fr" : "1fr 1fr")};
    grid-gap: 3em;
  }
`;
export const ItemMain = styled.div `
  display: grid;
  grid-template-rows: 1fr;
  justify-items: center;
  grid-area: heading;
  align-self: end;
`;
export const ItemImageContainer = styled.div `
  align-self: end;
  grid-area: image;
  width: 100%;
`;
export const ItemHeading = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${theme.fontBody};
  font-weight: bold;
  font-size: 1.5em;
  color: ${theme.subTitleColor};
`;
export const ItemDescription = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.6em;
  grid-area: desc;

  @media screen and ${device.laptopL} {
    font-size: 1.1em;
  }
`;
export const ItemContainer = styled.div `
  display: grid;
  grid-template-areas:
    "image"
    "-"
    "heading"
    "desc";
  grid-gap: 1.1em;
  justify-items: flex-start;
  line-height: 1.5em;

  @media screen and ${device.tablet} {
    grid-template-areas:
      "image heading heading"
      "image desc desc";
    grid-template-columns: 200px auto auto;
  }
`;
