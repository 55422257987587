import React, { memo } from "react";
import * as styles from "./styles";
import about from "assets/images/about-us/AboutUs.svg";
import Image from "components/Image";
import { useTranslation } from "react-i18next";
const { Container, Copy, ImageContainer, InformationContainer, Subtitle, Title } = styles;
export default memo(() => {
    const { t } = useTranslation("about");
    return (React.createElement(Container, null,
        React.createElement(Title, null, t("title")),
        React.createElement("div", null, t("subTitle")),
        React.createElement(InformationContainer, null,
            React.createElement(ImageContainer, null,
                React.createElement(Image, { alt: "about", src: about })),
            React.createElement(Copy, null, t("ourStory")))));
});
