import styled from "styled-components";
import { device } from "styles/utils";
import theme from "styles/theme";
export const Container = styled.main `
  margin: 2em 0 3em 0;
  background: ${theme.colorWhite};
`;
export const Heading = styled.section `
  padding: 2.5em 3em 0;

  @media screen and ${device.mobileM} {
    padding: 1em 1em 0 1.5em;
  }
`;
export const TitleContainer = styled.div `
  display: flex;
  align-items: center;
`;
export const Title = styled.h1 `
  font-size: 2rem;
  font-weight: 700;
`;
export const Summary = styled.div `
  font-size: 1.1rem;
  padding-top: 1em;
`;
export const Description = styled.div `
  margin: 1em;
  padding: 1em 1em 3em;

  @media screen and ${device.mobileL} {
    margin: 2em;
  }

  @media screen and ${device.laptopL} {
    margin: 1em 4em;
  }
`;
export const Logo = styled.img `
  height: 100%;
  max-height: 80px;
`;
