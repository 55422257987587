import React, { memo } from "react";
import * as styles from "./styles";
import Jumbotron from "components/Jumbotron";
import WhatWeDo from "components/WhatWeDo";
import SoftwareSolutions from "components/SoftwareSolutions";
import ContactUs from "components/ContactUs";
import Partners from "components/Partners";
const { Container, ItemContainer } = styles;
export default memo(() => {
    return (React.createElement(Container, null,
        React.createElement(Jumbotron, null),
        React.createElement(WhatWeDo, null),
        React.createElement("a", { id: "products" }),
        React.createElement(ItemContainer, null,
            React.createElement(SoftwareSolutions, null)),
        React.createElement(ItemContainer, null,
            React.createElement(Partners, null)),
        React.createElement(ItemContainer, null,
            React.createElement(ContactUs, null))));
});
