import styled from "styled-components";
import { slide } from "react-burger-menu";
export const Container = styled.div `
  width: 100%;
  position: fixed;
  background: white;
  z-index: 5;
  top: 0;
  display: flex;
  box-shadow: 0 0 12px 0 rgba(50, 50, 50, 0.64);
`;
export const Logo = styled.img `
  margin: 0.5em 2em;
  height: 50px;
`;
export const BurgerMenu = styled(slide) ``;
