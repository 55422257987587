import React from "react";
import * as styles from "./styles";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const { Container, NavLink, HashNavLink } = styles;
export default () => {
    const { t } = useTranslation("nav");
    return (React.createElement(Container, null,
        React.createElement(NavLink, null,
            React.createElement(Link, { to: "/solutions" }, t("solutions")),
            React.createElement("ul", null,
                React.createElement("li", null,
                    React.createElement(Link, { to: "/solutions/planningEngineers" }, t("Planning Engineers"))),
                React.createElement("li", null,
                    React.createElement(Link, { to: "/solutions/fieldWorkers" }, t("Field Workers"))),
                React.createElement("li", null,
                    React.createElement(Link, { to: "/solutions/dataEngineers" }, t("Data Engineers"))),
                React.createElement("li", null,
                    React.createElement(Link, { to: "/solutions/operationalPlanners" }, t("Operational Planners"))),
                React.createElement("li", null,
                    React.createElement(Link, { to: "/solutions/management" }, t("Management"))),
                React.createElement("li", null,
                    React.createElement(Link, { to: "/solutions/developers" }, t("Developers"))))),
        React.createElement(NavLink, null,
            React.createElement(HashNavLink, { to: "/#products" }, t("products")),
            React.createElement("ul", null,
                React.createElement("li", null,
                    React.createElement(Link, { to: "/solutions/energy-workbench" }, t("Energy Workbench"))),
                React.createElement("li", null,
                    React.createElement(Link, { to: "/solutions/ednar" }, t("EDNAR"))),
                React.createElement("li", null,
                    React.createElement(Link, { to: "/solutions/energy-workbench" }, t("Model Builders"))),
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement(HashNavLink, { to: "/#products" }, t("Siemens SINCAL"))),
                    React.createElement("li", null,
                        React.createElement(HashNavLink, { to: "/#products" }, t("Digsilent PowerFactory"))),
                    React.createElement("li", null,
                        React.createElement(HashNavLink, { to: "#products" }, t("OpenDSS"))),
                    React.createElement("li", null,
                        React.createElement(HashNavLink, { to: "#products" }, t("PandaPower"))),
                    React.createElement("li", null,
                        React.createElement(HashNavLink, { to: "#products" }, t("SmartGrid Toolbox")))),
                React.createElement("li", null,
                    React.createElement(Link, { to: "/solutions" }, t("Electrical Network Access"))),
                React.createElement("li", null,
                    React.createElement(Link, { to: "/solutions" }, t("Connection Agreements"))),
                React.createElement("li", null,
                    React.createElement(Link, { to: "/solutions" }, t("Grid Planning"))),
                React.createElement("li", null,
                    React.createElement(Link, { to: "/solutions" }, t("AMI Analytics"))),
                React.createElement("li", null,
                    React.createElement(Link, { to: "/solutions" }, t("CIM Integration"))))),
        React.createElement(NavLink, null,
            React.createElement(Link, { to: "/about-us" }, t("aboutUs"))),
        React.createElement(NavLink, null,
            React.createElement(Link, { to: "/contact" }, t("contact")))));
};
