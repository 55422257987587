import React, { useEffect } from "react";
import { Switch, Route } from "react-router";
import { useLocation } from "react-router-dom";
import Template from "components/Template";
const Routes = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "/", render: () => React.createElement(Template, { location: "home" }) }),
        React.createElement(Route, { path: "/solutions", render: ({ match }) => (React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: match.url, render: () => React.createElement(Template, { location: "solutions" }) }),
                React.createElement(Route, { exact: true, path: `${match.url}/planningEngineers`, render: () => (React.createElement(Template, { location: "solutions", target: "PLANNING_ENGINEERS" })) }),
                React.createElement(Route, { exact: true, path: `${match.url}/fieldWorkers`, render: () => (React.createElement(Template, { location: "solutions", target: "FIELD_WORKERS" })) }),
                React.createElement(Route, { exact: true, path: `${match.url}/dataEngineers`, render: () => (React.createElement(Template, { location: "solutions", target: "DATA_ENGINEERS" })) }),
                React.createElement(Route, { exact: true, path: `${match.url}/operationalPlanners`, render: () => (React.createElement(Template, { location: "solutions", target: "OPERATIONAL_PLANNERS" })) }),
                React.createElement(Route, { exact: true, path: `${match.url}/management`, render: () => (React.createElement(Template, { location: "solutions", target: "MANAGEMENT" })) }),
                React.createElement(Route, { exact: true, path: `${match.url}/developers`, render: () => (React.createElement(Template, { location: "solutions", target: "DEVELOPERS" })) }),
                React.createElement(Route, { exact: true, path: `${match.url}/planningEngineers`, render: () => (React.createElement(Template, { location: "solutions", target: "PLANNING_ENGINEERS" })) }),
                React.createElement(Route, { exact: true, path: `${match.url}/fieldWorkers`, render: () => (React.createElement(Template, { location: "solutions", target: "FIELD_WORKERS" })) }),
                React.createElement(Route, { exact: true, path: `${match.url}/dataEngineers`, render: () => (React.createElement(Template, { location: "solutions", target: "DATA_ENGINEERS" })) }),
                React.createElement(Route, { exact: true, path: `${match.url}/operationalPlanners`, render: () => (React.createElement(Template, { location: "solutions", target: "OPERATIONAL_PLANNERS" })) }),
                React.createElement(Route, { exact: true, path: `${match.url}/management`, render: () => (React.createElement(Template, { location: "solutions", target: "MANAGEMENT" })) }),
                React.createElement(Route, { exact: true, path: `${match.url}/developers`, render: () => (React.createElement(Template, { location: "solutions", target: "DEVELOPERS" })) }),
                React.createElement(Route, { exact: true, path: `${match.url}/planningEngineers`, render: () => (React.createElement(Template, { location: "solutions", target: "PLANNING_ENGINEERS" })) }),
                React.createElement(Route, { exact: true, path: `${match.url}/fieldWorkers`, render: () => (React.createElement(Template, { location: "solutions", target: "FIELD_WORKERS" })) }),
                React.createElement(Route, { exact: true, path: `${match.url}/dataEngineers`, render: () => (React.createElement(Template, { location: "solutions", target: "DATA_ENGINEERS" })) }),
                React.createElement(Route, { exact: true, path: `${match.url}/operationalPlanners`, render: () => (React.createElement(Template, { location: "solutions", target: "OPERATIONAL_PLANNERS" })) }),
                React.createElement(Route, { exact: true, path: `${match.url}/management`, render: () => (React.createElement(Template, { location: "solutions", target: "MANAGEMENT" })) }),
                React.createElement(Route, { exact: true, path: `${match.url}/developers`, render: () => (React.createElement(Template, { location: "solutions", target: "DEVELOPERS" })) }),
                React.createElement(Route, { exact: true, path: `${match.url}/ednar`, render: () => React.createElement(Template, { location: "ednar" }) }),
                React.createElement(Route, { exact: true, path: `${match.url}/energy-workbench`, render: () => React.createElement(Template, { location: "energyWorkbench" }) }),
                React.createElement(Route, { exact: true, path: `${match.url}/sincal`, render: () => React.createElement(Template, { location: "sincal" }) }))) }),
        React.createElement(Route, { exact: true, path: "/about-us", render: () => React.createElement(Template, { location: "about-us" }) }),
        React.createElement(Route, { exact: true, path: "/contact", render: () => React.createElement(Template, { location: "contact" }) })));
};
export default Routes;
