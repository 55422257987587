import React, { memo, useState } from "react";
import { useHistory } from "react-router-dom";
import * as styles from "./styles";
import * as commonStyles from "styles/common-styles";
import Heading from "components/Heading";
import planningEngineers from "assets/images/solutions/planners.svg";
import fieldWorkers from "assets/images/solutions/field-workers.svg";
import operationalPlanners from "assets/images/solutions/operators.svg";
import management from "assets/images/solutions/management.svg";
import data from "assets/images/solutions/data.svg";
import developers from "assets/images/solutions/developers.svg";
import SoftwareItem from "components/SoftwareItem";
import ednar from "assets/images/logos/ednar.svg";
import sincal from "assets/images/logos/sincal.svg";
import energyWorkbench from "assets/images/logos/ewb.svg";
import { MdClose } from "react-icons/all";
import { useTranslation } from "react-i18next";
import Image from "components/Image";
import { dataEngineersPath, developersPath, ednarPath, fieldWorkersPath, managementPath, operationalPlannersPath, energyWorkbenchPath, sincalPath, planningEngineersPath } from "../../util/path";
const { Container, TargetContainer, Target, TargetTitle, Solutions, CloseButton, SolutionsContainer, ImageContainer, DescriptionContainer, SubtitleContainer } = styles;
const { TitleContainer, Subtitle, Button } = commonStyles;
var Targets;
(function (Targets) {
    Targets["PLANNING_ENGINEERS"] = "PLANNING_ENGINEERS";
    Targets["FIELD_WORKERS"] = "FIELD_WORKERS";
    Targets["ANALYTICS"] = "ANALYTICS";
    Targets["OPERATIONAL_PLANNERS"] = "OPERATIONAL_PLANNERS";
    Targets["MANAGEMENT"] = "MANAGEMENT";
    Targets["DEVELOPERS"] = "DEVELOPERS";
})(Targets || (Targets = {}));
export default memo(({ target }) => {
    const [selectedTarget, setSelectedTarget] = useState(target ? Targets[target] : undefined);
    const { t } = useTranslation("software-solutions");
    const history = useHistory();
    const solutions = () => {
        const sols = [];
        if (selectedTarget === Targets.FIELD_WORKERS ||
            selectedTarget === Targets.MANAGEMENT ||
            selectedTarget === Targets.OPERATIONAL_PLANNERS) {
            sols.push(React.createElement(SoftwareItem, { key: "ednar", logoSrc: ednar, path: ednarPath, title: "EDNAR", subTitle: t("ednarSubTitle"), themeColor: "56cded" }));
        }
        if (selectedTarget === Targets.PLANNING_ENGINEERS ||
            selectedTarget === Targets.OPERATIONAL_PLANNERS ||
            selectedTarget === Targets.FIELD_WORKERS ||
            selectedTarget === Targets.MANAGEMENT ||
            selectedTarget === Targets.DEVELOPERS ||
            selectedTarget === Targets.ANALYTICS) {
            sols.push(React.createElement(SoftwareItem, { key: "energyWorkbench", logoSrc: energyWorkbench, path: energyWorkbenchPath, title: "Energy Workbench", subTitle: t("energyWorkbenchSubTitle"), themeColor: "47dcd8" }));
        }
        if (selectedTarget === Targets.PLANNING_ENGINEERS ||
            selectedTarget === Targets.OPERATIONAL_PLANNERS ||
            selectedTarget === Targets.FIELD_WORKERS ||
            selectedTarget === Targets.MANAGEMENT ||
            selectedTarget === Targets.DEVELOPERS ||
            selectedTarget === Targets.ANALYTICS) {
            sols.push(React.createElement(SoftwareItem, { key: "SINCAL", logoSrc: sincal, path: sincalPath, title: "SINCAL", subTitle: t("sincalSubTitle"), themeColor: "edcf00" }));
        }
        {
            /*Can be uncommented when we decide to put back evolve content*/
        }
        // if (
        //   selectedTarget === Targets.MANAGEMENT ||
        //   selectedTarget === Targets.PLANNING_ENGINEERS ||
        //   selectedTarget === Targets.ANALYTICS ||
        //   selectedTarget === Targets.DEVELOPERS
        // ) {
        //   sols.push(
        //     <SoftwareItem
        //       key={"evolve"}
        //       logoSrc={evolve}
        //       path={evolvePath}
        //       title={"Evolve Framework"}
        //       subTitle={t("evolveSubTitle")}
        //       themeColor={"65e063"}
        //     />
        //   );
        // }
        return sols;
    };
    const targetSelected = selectedTarget !== undefined;
    return (React.createElement(Container, null,
        targetSelected && (React.createElement(CloseButton, { onClick: () => setSelectedTarget(undefined) },
            React.createElement(MdClose, null))),
        React.createElement(TitleContainer, null,
            React.createElement(Heading, { title: t("title") }),
            React.createElement(Subtitle, null, t("subTitle"))),
        React.createElement(SolutionsContainer, { targetSelected: targetSelected },
            React.createElement(TargetContainer, { targetSelected: targetSelected },
                (selectedTarget === Targets.PLANNING_ENGINEERS ||
                    selectedTarget == undefined) && (React.createElement(Target, { targetSelected: targetSelected, imageSrc: planningEngineers, onClick: () => {
                        history.push(planningEngineersPath);
                        setSelectedTarget(Targets.PLANNING_ENGINEERS);
                    } },
                    React.createElement(ImageContainer, null,
                        React.createElement(Image, { src: planningEngineers, alt: "planning-engineers" })),
                    targetSelected && (React.createElement(TitleContainer, null,
                        React.createElement(TargetTitle, { targetSelected: targetSelected }, t("planningEngineers")))),
                    selectedTarget == undefined && (React.createElement(Button, null,
                        " ",
                        t("planningEngineers"))))),
                (selectedTarget === Targets.FIELD_WORKERS ||
                    selectedTarget === undefined) && (React.createElement(Target, { targetSelected: targetSelected, imageSrc: fieldWorkers, onClick: () => {
                        history.push(fieldWorkersPath);
                        setSelectedTarget(Targets.FIELD_WORKERS);
                    } },
                    React.createElement(ImageContainer, null,
                        React.createElement(Image, { src: fieldWorkers, alt: "field-workers" })),
                    targetSelected && (React.createElement(TitleContainer, null,
                        React.createElement(TargetTitle, { targetSelected: targetSelected }, t("fieldWorkers")))),
                    selectedTarget == undefined && (React.createElement(Button, null,
                        " ",
                        t("fieldWorkers"))))),
                (selectedTarget === Targets.ANALYTICS ||
                    selectedTarget === undefined) && (React.createElement(Target, { targetSelected: targetSelected, imageSrc: data, onClick: () => {
                        history.push(dataEngineersPath);
                        setSelectedTarget(Targets.ANALYTICS);
                    } },
                    React.createElement(ImageContainer, null,
                        React.createElement(Image, { src: data, alt: "data-engineers" })),
                    targetSelected && (React.createElement(TitleContainer, null,
                        React.createElement(TargetTitle, { targetSelected: targetSelected }, t("analytics")))),
                    selectedTarget == undefined && React.createElement(Button, null, t("analytics")))),
                (selectedTarget === Targets.OPERATIONAL_PLANNERS ||
                    selectedTarget === undefined) && (React.createElement(Target, { targetSelected: targetSelected, imageSrc: operationalPlanners, onClick: () => {
                        history.push(operationalPlannersPath);
                        setSelectedTarget(Targets.OPERATIONAL_PLANNERS);
                    } },
                    React.createElement(ImageContainer, null,
                        React.createElement(Image, { src: operationalPlanners, alt: "operational-planners" })),
                    targetSelected && (React.createElement(TitleContainer, null,
                        React.createElement(TargetTitle, { targetSelected: targetSelected }, t("operationalPlanners")))),
                    selectedTarget == undefined && (React.createElement(Button, null,
                        " ",
                        t("operationalPlanners"))))),
                (selectedTarget === Targets.MANAGEMENT ||
                    selectedTarget === undefined) && (React.createElement(Target, { targetSelected: targetSelected, imageSrc: management, onClick: () => {
                        history.push(managementPath);
                        setSelectedTarget(Targets.MANAGEMENT);
                    } },
                    React.createElement(ImageContainer, null,
                        React.createElement(Image, { src: management, alt: "management" })),
                    targetSelected && (React.createElement(TitleContainer, null,
                        React.createElement(TargetTitle, { targetSelected: targetSelected }, t("management")))),
                    selectedTarget == undefined && (React.createElement(Button, null,
                        " ",
                        t("management"))))),
                (selectedTarget === Targets.DEVELOPERS ||
                    selectedTarget === undefined) && (React.createElement(Target, { targetSelected: targetSelected, imageSrc: developers, onClick: () => {
                        history.push(developersPath);
                        setSelectedTarget(Targets.DEVELOPERS);
                    } },
                    React.createElement(ImageContainer, null,
                        React.createElement(Image, { src: developers, alt: "developers" })),
                    targetSelected && (React.createElement(TitleContainer, null,
                        React.createElement(TargetTitle, { targetSelected: targetSelected }, t("developers")))),
                    selectedTarget == undefined && (React.createElement(Button, null, t("developers")))))),
            selectedTarget === Targets.OPERATIONAL_PLANNERS && (React.createElement(SubtitleContainer, null, t("operationalPlannersSubtitle"))),
            selectedTarget === Targets.ANALYTICS && (React.createElement(SubtitleContainer, null, t("analyticsSubtitle"))),
            selectedTarget === Targets.DEVELOPERS && (React.createElement(SubtitleContainer, null, t("developersSubtitle"))),
            selectedTarget === Targets.FIELD_WORKERS && (React.createElement(SubtitleContainer, null, t("fieldWorkersSubtitle"))),
            selectedTarget === Targets.MANAGEMENT && (React.createElement(SubtitleContainer, null, t("managementSubtitle"))),
            selectedTarget === Targets.PLANNING_ENGINEERS && (React.createElement(SubtitleContainer, null, t("planningEngineersSubtitle")))),
        selectedTarget === Targets.OPERATIONAL_PLANNERS && (React.createElement(DescriptionContainer, null, t("operationalPlannersDescription")
            .split("\n")
            .map((text, idx) => (React.createElement("p", { key: "desc" + idx }, text))))),
        selectedTarget === Targets.ANALYTICS && (React.createElement(DescriptionContainer, null, t("analyticsDescription")
            .split("\n")
            .map((text, idx) => (React.createElement("p", { key: "desc" + idx }, text))))),
        selectedTarget === Targets.DEVELOPERS && (React.createElement(DescriptionContainer, null, t("developersDescription")
            .split("\n")
            .map((text, idx) => (React.createElement("p", { key: "desc" + idx }, text))))),
        selectedTarget === Targets.FIELD_WORKERS && (React.createElement(DescriptionContainer, null, t("fieldWorkersDescription")
            .split("\n")
            .map((text, idx) => (React.createElement("p", { key: "desc" + idx }, text))))),
        selectedTarget === Targets.MANAGEMENT && (React.createElement(DescriptionContainer, null, t("managementDescription")
            .split("\n")
            .map((text, idx) => (React.createElement("p", { key: "desc" + idx }, text))))),
        selectedTarget === Targets.PLANNING_ENGINEERS && (React.createElement(DescriptionContainer, null, t("planningEngineersDescription")
            .split("\n")
            .map((text, idx) => (React.createElement("p", { key: "desc" + idx }, text))))),
        React.createElement(Solutions, null, solutions())));
});
