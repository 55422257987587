import styled from "styled-components";
import theme from "styles/theme";
import { size } from "styles/utils";
export const Paragraph = styled.p `
  font-size: 0.9em;
  line-height: 2em !important;
`;
export const Image = styled.img `
  margin: 2em 0;
  justify-self: center;
`;
export const SectionTitle = styled.div `
  font-size: 1.1em;
  font-weight: bolder;
  margin: 1em 0;
`;
export const ListWrapper = styled.ul `
  list-style: disc;
  margin-left: 2em;
`;
export const InfoCardUl = styled.div `
  display: grid;
  grid-template-rows: auto;
  padding: 1em 0;
  font-size: 1em;
  line-height: 2em;
  color: ${theme.colorDarkGrey};
  background-color: white;
  ul {
    list-style: disc;
    margin-left: 2em;
  }

  @media screen and (max-width: ${size.laptopM}) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  @media screen and (max-width: ${size.mobileL}) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;
export const TopGrid = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;
  padding: 1em 0;
  align-items: start;

  img {
    justify-self: center;
    border-radius: 1em;
    max-width: 500px;
    width: 100%;
  }
`;
export const FindButtonContainer = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
