import React, { memo } from "react";
import { Link } from "react-router-dom";
import * as commonStyles from "styles/common-styles";
import Image from "components/Image";
import SoftwareSolutions from "components/SoftwareSolutions";
import ProductDetails from "components/SolutionDetails";
import ednar from "assets/images/logos/ednar.svg";
import image1 from "assets/images/ednar/image1.png";
import image2 from "assets/images/ednar/image2.png";
import image3 from "assets/images/ednar/image3.png";
import { useTranslation } from "react-i18next";
const { Description, DescriptionTitle, DescriptionCta, DescriptionList, DescriptionText, Grid, InfoCard, WideInfoCard, ProductContainer } = commonStyles;
export default memo(() => {
    const { t } = useTranslation("ednar");
    return (React.createElement(ProductContainer, null,
        React.createElement(ProductDetails, { logoSrc: ednar, title: t("title"), summary: t("summary"), description: React.createElement(React.Fragment, null,
                React.createElement(Description, null,
                    React.createElement(Grid, null,
                        React.createElement(InfoCard, null,
                            React.createElement(DescriptionTitle, null, t("description1Title")),
                            React.createElement(DescriptionList, null,
                                React.createElement("li", null, t("description1List1")),
                                React.createElement("li", null, t("description1List2")),
                                React.createElement("li", null, t("description1List3")),
                                React.createElement("li", null, t("description1List4")),
                                React.createElement("li", null, t("description1List5")),
                                React.createElement("li", null, t("description1List6")),
                                React.createElement("li", null, t("description1List7")),
                                React.createElement("li", null, t("description1List8")),
                                React.createElement("li", null, t("description1List9")),
                                React.createElement("li", null, t("description1List10")))),
                        React.createElement(InfoCard, null,
                            React.createElement(Image, { src: image1, alt: t("description1Image1Alt") })),
                        React.createElement(InfoCard, null,
                            React.createElement(Image, { src: image2, alt: t("description2Image1Alt") })),
                        React.createElement(InfoCard, null,
                            React.createElement(DescriptionTitle, null, t("description2Title")),
                            React.createElement(DescriptionText, null, t("description2Text1")),
                            React.createElement(DescriptionText, null, t("description2Text2")),
                            React.createElement(DescriptionText, null, t("description2Text3"))),
                        React.createElement(InfoCard, null,
                            React.createElement(DescriptionTitle, null, t("description3Title")),
                            React.createElement(DescriptionText, null, t("description3Text1")),
                            React.createElement(DescriptionText, null, t("description3Text2")),
                            React.createElement(DescriptionText, null, t("description3Text3"))),
                        React.createElement(InfoCard, null,
                            React.createElement(Image, { src: image3, alt: t("description3Image1Alt") })),
                        React.createElement(WideInfoCard, null,
                            React.createElement(DescriptionTitle, null, t("description4Title")),
                            React.createElement(Link, { to: "/assets/files/ednar_featureTour.pdf", target: "_blank" },
                                React.createElement(DescriptionCta, null, t("description4Cta"))))))) }),
        React.createElement(SoftwareSolutions, null)));
});
