import React, { memo } from "react";
import { Link } from "react-router-dom";
import * as styles from "./styles";
import ednar from "assets/images/logos/ednar.svg";
import energyWorkbench from "assets/images/logos/ewb.svg";
import sincal from "assets/images/logos/sincal.svg";
import { useTranslation } from "react-i18next";
import { ednarPath, energyWorkbenchPath, sincalPath, solutionsPath } from "../../util/path";
import Heading from "components/Heading";
import SoftwareItem from "components/SoftwareItem";
import * as commonStyles from "styles/common-styles";
const { Container, List, FindButtonContainer } = styles;
const { TitleContainer, Button: FindButton } = commonStyles;
export default memo(() => {
    const { t } = useTranslation("software-solutions");
    return (React.createElement(Container, null,
        React.createElement(TitleContainer, null,
            React.createElement(Heading, { title: t("products") })),
        React.createElement(List, null,
            React.createElement(SoftwareItem, { logoSrc: ednar, title: "EDNAR", subTitle: t("ednarSubTitle"), path: ednarPath, themeColor: "56cded" }),
            React.createElement(SoftwareItem, { logoSrc: energyWorkbench, title: "Energy Workbench", subTitle: t("energyWorkbenchSubTitle"), path: energyWorkbenchPath, themeColor: "47dcd8" }),
            React.createElement(SoftwareItem, { logoSrc: sincal, title: "SINCAL", subTitle: t("sincalSubTitle"), path: sincalPath, themeColor: "edcf00" })),
        React.createElement(FindButtonContainer, null,
            React.createElement(Link, { to: solutionsPath },
                React.createElement(FindButton, null, t("buttonText"))))));
});
