import styled from "styled-components";
import { device, size } from "styles/utils";
import Headroom from "react-headroom";
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  max-width: ${size.desktop};
  width: 100%;
  padding-top: 68px;

  @media screen and ${device.mobileL} {
    padding-top: ${({ isHome }) => (!isHome ? "68px" : 0)};
  }

  @media screen and ${device.laptop} {
    padding-top: ${({ isHome }) => (!isHome ? "68px" : 0)};
  }
`;
export const Content = styled.div `
  flex: 1;
  display: flex;
  justify-content: center;
  max-width: 100%;
`;
export const HeaderRoom = styled(Headroom) `
  .headroom--unfixed {
    display: none;
  }
`;
