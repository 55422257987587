import styled from "styled-components";
import theme from "styles/theme";
import { device, size } from "styles/utils";
export const Description = styled.section `
  align-self: center;
  padding: 1em 0;
`;
export const DescriptionTitle = styled.div `
  font-weight: 500;
  font-size: 1.5rem;
`;
export const DescriptionText = styled.div `
  color: ${theme.colorDarkGrey};
`;
export const DescriptionCta = styled.div `
  font-size: 1em;
  font-weight: 400;
`;
export const DescriptionList = styled.ul `
  list-style-type: disc;
  padding-left: 2.2em;

  & li {
    padding: 0.5em 0;
  }

  & li:first-of-type {
    padding-top: 0;
  }

  & li:last-of-type {
    padding-bottom: 0;
  }
`;
export const Grid = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;
  padding: 1em 0;
  align-items: center;

  &.aligned-top {
    align-items: start;
  }

  img {
    justify-self: center;
    border-radius: 1em;
    max-width: 500px;
    width: 100%;

    &.question-image {
      max-width: 45%;

      @media screen and ${device.laptop} {
        max-width: 22%;
      }
    }
  }
`;
export const ImageSet = styled.div `
  display: flex;

  img {
    max-width: 50%;
    padding: 0 0.5em;
  }
`;
export const Subtitle = styled.div `
  color: ${theme.colorDarkGrey};

  @media screen and ${device.mobileL} {
    font-size: 0.9em;
  }
`;
export const TitleContainer = styled.div `
  display: grid;
  grid-template-rows: auto;
  grid-gap: 1em;

  @media screen and (max-width: ${size.mobileL}) {
    padding: 0;
  }
`;
export const InfoCard = styled.div `
  display: grid;
  grid-template-rows: auto;
  grid-gap: 2em;
  padding: 1em 3em;
  font-size: 1em;
  line-height: 2em;
  color: ${theme.colorDarkGrey};
  background-color: white;

  @media screen and (max-width: ${size.laptopS}) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  @media screen and (max-width: ${size.mobileL}) {
    grid-column-start: 1;
    grid-column-end: 3;
    padding: 0;
  }
`;
export const WideInfoCard = styled.div `
  display: grid;
  grid-template-rows: auto;
  grid-gap: 2em;
  padding: 1.5em;
  font-size: 1em;
  line-height: 2em;
  color: ${theme.colorDarkGrey};
  background-color: white;
  grid-column-start: 1;
  grid-column-end: 3;

  &.quote-image {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  img {
    max-width: unset;
  }

  iframe {
    width: 100%;
  }

  @media screen and (max-width: ${size.mobileL}) {
    padding: 0;
  }
`;
export const DescriptionTitleCenter = styled.div `
  font-weight: 500;
  font-size: 1.5rem;
  text-align: center;
`;
export const Button = styled.button `
  padding: 1em 2em;
  background-color: ${theme.primaryColor};
  font-family: ${theme.fontBody};
  font-size: 0.9rem;
  cursor: pointer;
  color: ${theme.colorWhite};
  font-weight: lighter;
  border: none;

  @media (hover: hover) {
    :hover {
      background-color: ${theme.tertiaryColor};
      transition: background-color 0.5s ease;
    }
  }
`;
export const ProductContainer = styled.div `
  width: 100%;
  @media screen and ${device.laptopM} {
    width: 77%;
  }
`;
export const ContentLink = styled.a `
  line-height: 1em;
  font-weight: 400;
  text-decoration: inherit;
  color: #416d81;
  display: inline-block;

  :hover {
    text-decoration: underline;
  }
`;
