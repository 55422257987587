import React, { memo } from "react";
import { Link } from "react-router-dom";
import * as styles from "./styles";
import { useTranslation } from "react-i18next";
import Image from "components/Image";
const { Item, LogoContainer, ItemTitle, ItemSubtitle, KnowMoreButton, Container } = styles;
export default memo(({ logoSrc, title, subTitle, path, platform, themeColor }) => {
    const { t } = useTranslation("software-solutions");
    return (React.createElement(Container, null,
        React.createElement(Item, { platform: platform, themeColor: themeColor },
            React.createElement(LogoContainer, null,
                React.createElement(Image, { src: logoSrc, alt: title })),
            React.createElement(ItemTitle, null, title),
            React.createElement(ItemSubtitle, null, subTitle),
            React.createElement(Link, { to: path },
                React.createElement(KnowMoreButton, null, t("knowMore"))))));
});
