import React from "react";
import GlobalStyles from "styles/global";
import Header from "components/Header";
import Home from "pages/Home";
import Footer from "components/Footer";
import Solutions from "pages/Solutions";
import AboutUs from "pages/AboutUs";
import Contact from "pages/Contact";
import Ednar from "pages/Ednar";
import EnergyWorkbench from "pages/EnergyWorkbench";
//import PowerFactory from "pages/PowerFactory";
import Sincal from "pages/Sincal";
import * as styles from "./styles";
import { useMedia } from "use-media";
import { size } from "styles/utils";
const { Container, Content, HeaderRoom } = styles;
export default (props) => {
    const { location, target } = props;
    const isHome = location === "home";
    const isWide = useMedia({ minWidth: size.laptopS });
    return (React.createElement(React.Fragment, null,
        React.createElement(GlobalStyles, null),
        React.createElement(Container, { isHome: isHome },
            isHome && isWide ? (React.createElement(HeaderRoom, { pinStart: 600 },
                React.createElement(Header, null))) : (React.createElement(Header, null)),
            React.createElement(Content, null,
                isHome && React.createElement(Home, null),
                location === "solutions" && React.createElement(Solutions, { target: target }),
                location === "ednar" && React.createElement(Ednar, null),
                location === "energyWorkbench" && React.createElement(EnergyWorkbench, null),
                location === "sincal" && React.createElement(Sincal, null),
                location === "about-us" && React.createElement(AboutUs, null),
                location === "contact" && React.createElement(Contact, null)),
            React.createElement(Footer, null))));
};
