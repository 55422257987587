import styled from "styled-components";
export const Image = styled.img `
  margin: 2em 0;
  max-height: 20em;
  justify-self: center;
`;
export const ImageCaptionCenter = styled.div `
  font-size: 0.8em;
  margin: 1em 0;
  justify-self: center;
`;
